/*** Map view station cards (when pin is selected) ***/

// Best price discount flags...

.map-card-outer {
  .map-card-flags {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
  }

  &.flag-best .map-card-flags {
    height: 20px;
  }

  &.flag-partner .map-card-flags,
  &.flag-best.flag-partner .map-card-flags {
    height: 36px;
  }

  .map-card-flag-best {
    display: none;
  }

  .map-card-flag-partner {
    display: none;
  }

  &.flag-best .map-card-flag-best {
    background: $red;
    border-bottom: 1px solid #ffffff;
    color: #ffffff;
    display: block;
    font-size: 12px;
    font-weight: 700;
    height: 20px;
    padding: 2px 15px;
    text-transform: uppercase;

    ion-icon {
      margin-right: 4px;
      margin-top: -2px;
      vertical-align: middle;
    }
  }

  &.flag-partner .map-card-flag-partner {
    // Using inline svg below for better offline experience
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4000 63'%3E%3Cpolyline points='11.9 0 4000 0 4000 63 0 63' style='fill:%23a9875a'/%3E%3Cpolyline points='26.86 0 4000 0 4000 63 15 63' style='fill:%231c3559'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
    display: block;
    font-size: 12px;
    font-weight: 700;
    height: 30px;
    min-width: 195px;
    padding: 7px 5px 5px 20px;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 0;
    width: 58%;
    margin-top: 6px;

    span {
      vertical-align: middle;
    }

    img {
      height: 30px;
      position: absolute;
      right: 15px;
      top: -6px;
      vertical-align: middle;
      width: 30px;
    }
  }
}

// Map card...

.map-card-outer {
  pointer-events: all;

  .map-card {
    background: $blue-bright;
    color: #ffffff;

    // Top section info...
    .map-card-info {
      display: flex;
      flex-direction: column;
      margin-bottom: 5px;

      .map-card-info-left {        
        .map-card-station-text {
          margin: 16px 20px 0px;
        }

        h3 {
          color: #ffffff;
          font-size: 18px;
          margin: 0;
        }

        div {
          font-size: 14px;
        }

        ion-spinner {
          display: block;
          margin: 0 auto;
        }
      }

      .map-card-info-right {
        flex: 0 0 auto;
        margin-left: auto;
        text-align: right;

        span.label-directions {
          display: block;
          font-size: 12px;
        }

        ion-button {
          --padding-bottom: 0px;
          --padding-end: 6px;
          --padding-start: 4px;
          --padding-top: 4px;

          ion-icon {
            font-size: 38px;
          }
        }
      }
    }

    // Pricelist...
    .map-card-pricelist-outer {
      --background: var(--ion-color-primary);
      height: 120px;
    }

    .map-card-pricelist {
      padding: 20px 0 5px 0;
      white-space: nowrap;
    }

    .map-card-price-block-outer {
      color: #ffffff;
      display: inline-block;
      margin: 0 4px 0 0;
      text-align: left;

      + .map-card-price-block-outer {
        margin-left: 6px;
      }

      .updated-icon {
        width: 10px;
      }

      span.updated {
        color: $grey-dark;
        font-size: 11px;
        letter-spacing: -0.3px;
        line-height: 1.6;
        max-width: 100px;
        vertical-align: top;
      }

      .selected-price-block-background, .pump-icon-container {
        background-color: var(--ion-color-secondary);
        position: absolute;
      }

      .selected-price-block-background {
        border-radius: 16px 0;
        height: 86px;
        left: 20px;
        top: 13px;
        width: 84px;
        z-index: -20;
      }

      .pump-icon-container {
        border-radius: 50px;
        height: 33px;
        left: 4px;
        top: 38px;
        width: 33px;
        z-index: -10;

        .pump-icon {
          margin-left: 18%;
          margin-top: 30%;
        }
      }
      
      .best-price-icon-container {
        background: $red;
        border-radius: 26px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3); 
        height: 26px;
        left: 92px;
        position: absolute;
        top: 10px;
        width: 26px;

        .check-icon {
          height: 18px;
          margin-left: 15%;
          margin-top: 16%;
          width: 18px;
        }
      }

      .map-card-price-block {
        background: #ffffff;
        border-radius: 10px 0;
        color: $blue;
        display: flex;
        flex-direction: column;
        height: 72px;
        justify-content: space-between;
        margin-bottom: 3px;
        padding: 10px 10px 10px 12px;
        width: 84px;

        &.selected {
          margin-left: 28px;

          span.label-fueltype {
            font-weight: 700;
          }

          span.price {
            font-weight: 700;
          }
        }

        span.label-fueltype {
          display: block;
          font-size: 14px;
          font-weight: 500;
          text-align: left;
        }

        span.price {
          display: block;
          font-size: 14px;
          font-weight: 500;
          text-align: left;
        }
      }
    }

    .map-card-button-group {
      display: flex;
      width: 100%;
      overflow: hidden;
  
      .map-card-button {
        --border-radius: 0;

        border-top: 0.5px solid rgba(214, 233, 255, 0.5);
        border-bottom: 0.5px solid rgba(214, 233, 255, 0.5);
        border-right: 0.5px solid rgba(214, 233, 255, 0.5);
        flex: 1;
        height: 48px;
        margin: 0;
        padding: 1px;
        white-space: pre-wrap;

        &.last-button {
          border-right: 0;
        }

        &.selected {
          --background-activated: var(--ion-color-secondary);
          --background-focused: var(--ion-color-secondary);
          --background-hover: var(--ion-color-secondary);
          --background: var(--ion-color-secondary);
          background: var(--ion-color-secondary);
          
          border-bottom: 0;
        }

        span.label-distance {
          display: block;
          font-size: 12px;
          font-weight: 700;
          width: 100%;
        }

        ion-icon {
          height: 23px;
          margin-right: 8px;
          width: 23px;
        }
        
        span, div {
          font-size: 12px;
          text-align: left;
        }
      }
    }

    .mobile-spacer {
      background-color: var(--ion-color-primary);
      height: 24px;

      &.reportViewOpen {
        height: 0;
      }
    }
  }

  .report-container {
    h4 {
      color: #fff;
      font-family: 'Karla';
      font-weight: 400;
    }

    .validation-error {
      align-items: center; /* align vertical */
      color: $salmon;
      display: flex;
      font-size: 12px;
      font-weight: 700;
      justify-content: center;
      margin-top: 8px;

      ion-icon {
        margin-right: 4px;
      }
    }
  }

  // Membership discounts on...
  &.discounts-on.flag-partner .map-card {
    background: var(--ion-color-midnight);

    .map-card-pricelist-outer {
      --background: var(--ion-color-midnight);

      .selected-price-block-background, .pump-icon-container, .discounted-price-block-background {
        background-color: $gold;
      }

      .discounted-price-block-background {
        border-radius: 16px 0;
        top: 27px;
        height: 72px;
        position: absolute;
        width: 84px;
        z-index: -20;
      }
    }

    .map-card-info-right ion-button {
      --background-activated: var(--ion-color-midnight-shade);
      --background-focused: var(--ion-color-midnight-shade);
      --background-hover: var(--ion-color-midnight-tint);
      --background: var(--ion-color-midnight);
    }
    
    .map-card-button-group ion-button {
      --background-activated: var(--ion-color-midnight-shade);
      --background-focused: var(--ion-color-midnight-shade);
      --background-hover: var(--ion-color-midnight-tint);
      --background: var(--ion-color-midnight);
     
      border-top: 0.5px solid rgba(214, 233, 255, 0.5);
      border-bottom: 0.5px solid rgba(214, 233, 255, 0.5);
      border-right: 0.5px solid rgba(214, 233, 255, 0.5);

      &.last-button {
        border-right: 0;
      }

      &.selected {
        --background-activated: var(--ion-color-secondary);
        --background-focused: var(--ion-color-secondary);
        --background-hover: var(--ion-color-secondary);
        --background: var(--ion-color-secondary);
        background: var(--ion-color-secondary);

        border-bottom: 0;
      }    
    }

    .mobile-spacer {
      background-color: var(--ion-color-midnight);
    }
  }

  &.discounts-on.flag-partner .map-card .map-card-price-block-outer .map-card-price-block {
    border-color: $gold;
  }

  &.discounts-on.flag-partner .map-card .map-card-price-block-outer .map-card-price-block.selected {
    border-color: $gold;
  }
}

// Tablet & Desktop styling...

@media (min-width: $tablet-up) {
  .map-card-outer {
    margin: 0 10px 20px 10px;
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: auto;

    .map-card {
      border-radius: 0 0 8px 8px;
      max-width: 100%;
      padding-bottom: 0;

      .mobile-spacer {
        height: 0;
      }

      .map-card-info {
        .map-card-info-left {
          .map-card-station-text {
            margin: 16px 30px 0px;
          }

          .map-card-pricelist-outer {
            height: 105px;
          }
        }

        .map-card-price-block-outer {
          .selected-price-block-background {
            left: 30px;
          }

          .pump-icon-container {
            left: 14px;
          }

          .best-price-icon-container {
            left: 102px;
          }

          .map-card-price-block {
            &.selected {
              margin-left: 38px;
            }
          }
        }
      }
    }

    .map-card-button-group {
      border-left: 0.5px solid rgba(214, 233, 255, 0.5);
      border-right: 0.5px solid rgba(214, 233, 255, 0.5);
      margin: 0 auto;
      max-width: 375px;
    }

    &.list-is-open {
      -ms-transform: translateX(190px);
      -webkit-transform: translateX(190px);
      margin: 0 auto 20px auto;
      max-width: calc(100% - 400px);
      transform: translateX(190px);
      width: calc(100% - 400px);
    }
  }
}

@media (min-width: $desktop-up) {
  .map-card-outer {
    margin: 0 auto 20px auto;
    max-width: 800px;
    width: auto;

    &.list-is-open {
      -ms-transform: translateX(190px);
      -webkit-transform: translateX(190px);
      max-width: calc(100% - 420px);
      transform: translateX(190px);
    }
  }
}

// Very large screen...
@media (min-width: 1640px) {
  .map-card-outer {
    margin: 0 auto 20px auto;
    max-width: 840px;
    transition: none;
    width: 840px;

    &.list-is-open {
      -ms-transform: none;
      -webkit-transform: none;
      max-width: 840px;
      transform: none;
      width: 840px;
    }
  }
}
