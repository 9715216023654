.member-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 2rem;
  min-height: 100vh;

  h1,
  section {
    margin-bottom: 2rem;
  }
}

.barcode-display {
  max-width: 90vw;
  width: 345px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: 1px solid $grey-light;
  border-radius: 10px;
}

.member-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0 1rem 0;

  @media screen and (max-width: 374px) {
    padding: 1rem;
  }

  .detail-container {
    white-space: nowrap;
    padding-bottom: 0.25rem;
  }

  .member-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0.5rem;
  }

  .bold-blue-detail {
    color: #2376d8;
    font-weight: 700;
    white-space: nowrap;
  }
}

.display-inline {
  display: inline;
}

.member-card-context {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .success-header {
    padding-left: 3rem;
    padding-right: 3rem;
    h1 {
      color: #256bcd;
    }
  }

  .error-header-container {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #fbe6e7;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: relative;
    text-align: center;

    h1 {
      color: #e32c2d;
      white-space: wrap;
      font-size: 20px;
    }

    .x-icon-header-container {
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #fbe6e7;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .x-icon-header {
        width: 2.25rem;
        height: 2.25rem;
        padding: 0.1rem;
        color: #e32c2d;
      }
    }
  }

  h1 {
    margin: 0 0 1rem 0;
    white-space: nowrap;
  }

  span {
    color: #5b5b5b;
    font-size: 14px;
  }
}

.ract-logo {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &.large {
    width: 70px;
  }
  &.small {
    width: 35px;
  }
}

.rescan-button-container {
  padding-top: 1.5rem;
  padding-bottom: 3rem;
}

.help-text {
  padding: 0 1.5rem 0.5rem 1.5rem;
  color: $grey-dark;
  line-height: 1.75;
  max-width: 350px;
  font-size: 14px;
}

.removeCardButtonContainer {
  margin-top: 1rem;
}

.barcodeContainer {
  margin-top: 0.5rem;
}

.closeButton {
  margin-top: 3rem;
}
