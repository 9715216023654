.header-toolbar-floating {
  @extend .padding-top-safe-area;

  max-width: 430px;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 999;

  ion-searchbar {
    --box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
  }

  .header-toolbar-filters {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    padding: 4px 12px;

    ion-button {
      --box-shadow: 0 0 8px rgba(0, 0, 0, 0.16);
      flex: 0 0 auto;
      font-weight: 700;
    }

    ion-button.header-toolbar-fuel {
      --padding-start: 38px;
      flex: 1 1 auto;
      font-size: 13px;
      min-width: 0;

      ion-icon {
        font-size: 18px;
        left: -22px;
        margin-right: 4px;
        position: absolute;
      }

      span.truncate {
        padding: 4px 0;
      }
    }

    ion-button.header-toolbar-members {
      margin-right: 0 !important;

      --border-style: solid;
      --border-color: var(--ion-color-gold);
      --border-width: 2px;

      --background-activated: var(--ion-color-white-shade);
      --background-focused: var(--ion-color-white-shade);
      --background-hover: var(--ion-color-white-shade);
      --background: var(--ion-color-white);

      --color-activated: var(--ion-color-gold-shade);
      --color: var(--ion-color-gold);

      &.toggle-on {
        --background-activated: var(--ion-color-midnight-shade);
        --background-focused: var(--ion-color-midnight-shade);
        --background-hover: var(--ion-color-midnight-shade);
        --background: var(--ion-color-midnight);

        --color-activated: var(--ion-color-white-shade);
        --color: var(--ion-color-white);
      }

      ion-icon {
        font-size: 26px;
      }
    }
  }
}

@media (min-width: $tablet-up) {
  .header-toolbar-floating {
    margin-left: 14px;
    width: 360px;
  }
}
// Search typeahead...

.pac-container {
  -ms-transform: translateY(-2px);
  -webkit-transform: translateY(-2px);

  border-radius: 8px;
  transform: translateY(-2px);

  .pac-item {
    margin: 2px;
    border: none;
  }

  &:after {
    background-image: none !important;
    height: 0px;
  }
}

// Desktop view list button...

.list-expand {
  background: $blue-bright;
  border-radius: 0 22px 22px 0;
  box-shadow: $box-shadow;
  color: #ffffff;
  height: 44px;
  left: -14px;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: center;
  top: calc(constant(safe-area-inset-top) + 11px);
  top: calc(env(safe-area-inset-top) + 11px);
  transition-property: width, padding;
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 22px;
  z-index: 999;

  ion-icon {
    vertical-align: middle;
  }

  &:active,
  &:focus {
    background: var(--ion-color-primary-tint);
  }

  &:hover {
    width: 26px;
    background: var(--ion-color-primary-tint);
  }

  ion-icon.list-expand-open-icon {
    display: inline-block;
    margin-left: -1px;
  }
  ion-icon.list-expand-close-icon {
    display: none;
  }

  &.expanded {
    -ms-transform: translateX(50%);
    -webkit-transform: translateX(50%);

    border-radius: 15px;
    height: 30px;
    left: auto;
    right: -5px;
    top: calc(constant(safe-area-inset-top) + 17px);
    top: calc(env(safe-area-inset-top) + 17px);
    transform: translateX(50%);
    width: 30px;

    ion-icon.list-expand-open-icon {
      display: none;
    }
    ion-icon.list-expand-close-icon {
      display: inline-block;
    }
  }
}

// Offline Card...
.offline-card {
  align-items: center;
  background: #747680;
  color: #ffffff;
  display: flex;
  font-size: 16px;
  justify-content: center;
  margin: 0 auto;
  padding: 12px;
  text-align: center;
  width: 260px;

  svg {
    fill: #ffffff;
    height: 22px;
    margin-right: 15px;
    width: 22px;
  }
}

// Report container shared style
ion-button.report-container-btn {
  margin: 8px;
  max-width: 125px;
  min-width: 125px;
  
  ion-icon {
    right: 2px;
    font-size: 12px;
    position: absolute;
  }
}

.main-nav {
  @extend .padding-top-safe-area;
}
