/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// Core styles...
@import "scss/core/basic";
@import "scss/core/layout";

// Elements...
@import "scss/elements/buttons";
@import "scss/elements/forms";

// Modules...
@import "scss/modules/onboarding";
@import "scss/modules/sidepanels";
@import "scss/modules/find-fuel-header";
@import "scss/modules/find-fuel-map-controls";
@import "scss/modules/find-fuel-map-card";
@import "scss/modules/find-fuel-list";
@import "scss/modules/find-fuel-list-card";
@import "scss/modules/member-card";
@import "scss/modules/error-pages";
@import "scss/modules/barcode-scanner";

/** Swiper use */
@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.scanner-ui {
  display: none;
  visibility: hidden;
  animation: fadeIn 0.5s;
  --background: transparent;
  --ion-background-color: transparent;
}
.scanner-hide {
  visibility: visible;
}

body.scanner-active {
  background-color: transparent;
}
body.scanner-active .scanner-ui {
  --background: unset;
  --ion-background-color: unset;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  visibility: unset;
}

body.scanner-active .scanner-hide {
  visibility: hidden;
  --background: transparent;
  --ion-background-color: transparent;
}

body.scanner-active {
  --background: transparent;
  --ion-background-color: transparent;
}

// Additional swiper styles to retain look of deprecated ion-slides
swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #3880ff);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
  --swiper-navigation-color: rgba(0, 0, 0, 0);
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
