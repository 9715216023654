.square {
  height: 25vh;
  margin-bottom: 4rem;
  overflow: hidden;
  position: relative;
  transition: 0.3s;
  width: 80vw;
}

.square:after {
  content: '';
  top: 0;
  display: block;
  padding-bottom: 100%;
}

.square > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.surround-cover {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.6);
}

.barcode-scanner--area--container {
  width: 80%;
  max-width: min(500px, 80vh);
  margin: auto;
}

.barcode-scanner--area--outer {
  display: flex;
  border-radius: 1em;
}

.barcode-scanner--area--inner {
  margin: 1rem;
  border: 3px dashed var(--ion-color-primary);
  border-radius: 1rem;
}

.barcode-info {
  background-color: white;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  max-width: 300px;
  padding: 1rem;
  text-align: center;
  z-index: 1;
}

.barcode-info > p {
  margin: 0;
}

.barcode-scanner-close {
  width: 150px;
}

.barcode-close-icon {
  margin-right: 0.5rem;
}

.barcode-spinner {
  position: absolute;
  top: 0;
  align-items: center;
  background:rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}
