.list-view {
  -ms-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  background: var(--ion-color-secondary);
  bottom: 0;
  height: 100vh;
  left: 0;
  padding-top: calc(constant(safe-area-inset-top) + 112px);
  padding-top: calc(env(safe-area-inset-top) + 112px);
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 100vw;
  z-index: 9;

  &.active {
    -ms-transform: translateX(0);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media (min-width: $tablet-up) {
  .list-view {
    width: 380px;
    z-index: 999;

    &.active {
      z-index: 9;
    }
  }
}
