/* ----------------------------------------------------------------------------
	Error pages
---------------------------------------------------------------------------- */

.generic-no-network {
  align-items: center;
  background: #f4f5f8;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  padding: 0 20px;
  text-align: center;

  svg {
    fill: var(--ion-color-medium);
    height: 60px;
    width: 60px;
  }
}
