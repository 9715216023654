// Basic button styling...
ion-button {
  --border-radius: 2.4em;
  min-height: auto;

  &[size='small'] {
    --border-radius: 1.33em;
    height: 2.66em;

    ion-icon {
      font-size: 16px;
    }
  }

  &[size='large'] {
    --border-radius: 2.4em;
  }

  &.btn-wide {
    min-width: 140px;
  }

  &[fill='outline'] {
    --border-width: 2px;
  }
}

ion-button.btn-icon {
  --border-radius: 22px;
  --box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  --padding-end: 0;
  --padding-start: 0;
  height: 44px;
  width: 44px;
}

ion-menu-button.burger-menu {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);
  --border-radius: 50%;
  --padding: 12px;

  position: absolute;
  top: 13px;
  left: 15px;
  width: 39px;
  height: 39px;
  z-index: 1;
}

// Toggle button group...
.button-group-toggle {
  background: var(--ion-color-primary-contrast);
  border-radius: 17.29px;
  box-shadow: $box-shadow;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  height: 34.58px;
  margin: 4px 2px 4px 0;
  position: relative;
  width: 96px;

  &:before {
    background: var(--ion-color-primary);
    border-radius: 15.29px;
    content: '';
    display: block;
    height: 30.58px;
    left: 2px;
    position: absolute;
    right: auto;
    top: 2px;
    transition: transform 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    width: 50%;
  }

  &.toggle-right:before {
    -ms-transform: translateX(calc(100% - 4px));
    -webkit-transform: translateX(calc(100% - 4px));
    transform: translateX(calc(100% - 4px));
  }

  button {
    background: none;
    border-radius: 17.29px;
    color: var(--ion-color-primary);
    font-size: 13px;
    font-weight: 700;
    height: 34.58px;
    left: 2px;
    outline: none;
    padding: 0;
    position: relative;
    transition: color 0.2s cubic-bezier(0.19, 1, 0.22, 1);
    width: 50%;
    z-index: 1;

    + button {
      left: -2px;
    }

    &.active {
      color: var(--ion-color-primary-contrast);
    }
  }
}
