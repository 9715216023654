select.input-select-lg {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: white;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='none' stroke='%232376D8' stroke-linecap='round' stroke-linejoin='round' stroke-width='48' d='M112 184l144 144 144-144'/%3E%3C/svg%3E");
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  background-size: 18px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.22);
  color: var(--ion-color-primary);
  font-size: 1rem;
  margin-top: 8px;
  max-width: 320px;
  padding: 18px;
  width: 100%;
}

ion-input.item-input {
  --border-radius: 20px;
}

ion-searchbar.search-lg {
  --background: var(--ion-color-primary-contrast);
  --border-radius: 22px;
  --padding: 0;
  height: 56px;

  input {
    height: 44px !important;
    padding-inline-start: 82px !important;
    &::placeholder {
      --placeholder-opacity: 1;
      font-size: 14px;
    }
    &::-webkit-input-placeholder {
      --placeholder-opacity: 1;
      font-size: 14px;
    }
  }

  ion-icon.searchbar-search-icon {
    left: 8px;
    padding-left: 48px;
    top: 4px;
    width: 16px;
  }
  button.searchbar-clear-button {
    top: 4px;
  }

  --clear-button-color: var(--ion-color-primary);
  --color: var(--ion-color-primary);
  --icon-color: var(--ion-color-primary);
  --placeholder-color: var(--ion-color-primary);
}
