// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Fonts **/

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Poppins-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Poppins-Medium.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Karla-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Karla-Bold.ttf');
  font-display: swap;
}


/** Ionic CSS Variables **/
:root {

  --ion-font-family: 'Karla', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;

  /** primary - using Blue (Bright?) #2376D8 **/
  --ion-color-primary: #2376D8;
  --ion-color-primary-rgb: 35,118,216;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #1f68be;
  --ion-color-primary-tint: #3984dc;

  /** secondary - using RACT Blue #204B87 **/
  --ion-color-secondary: #204B87;
  --ion-color-secondary-rgb: 32, 75, 135;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1c4277;
  --ion-color-secondary-tint: #365d93;

  /** tertiary - using ... #2376D8 **/
  // --ion-color-tertiary: #2376D8;
  // --ion-color-tertiary-rgb: 28, 53, 89;
  // --ion-color-tertiary-contrast: #FFFFFF;
  // --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  // --ion-color-tertiary-shade: #192f4e;
  // --ion-color-tertiary-tint: #33496a;

  --ion-color-midnight: #1C3559;
  --ion-color-midnight-rgb: 28,53,89;
  --ion-color-midnight-contrast: #ffffff;
  --ion-color-midnight-contrast-rgb: 255,255,255;
  --ion-color-midnight-shade: #192f4e;
  --ion-color-midnight-tint: #33496a;

  --ion-color-gold: #A9875A;
  --ion-color-gold-rgb: 169,135,90;
  --ion-color-gold-contrast: #000000;
  --ion-color-gold-contrast-rgb: 0,0,0;
  --ion-color-gold-shade: #95774f;
  --ion-color-gold-tint: #b2936b;

  --ion-color-red: #E32C2D;
  --ion-color-red-rgb: 227,44,45;
  --ion-color-red-contrast: #ffffff;
  --ion-color-red-contrast-rgb: 255,255,255;
  --ion-color-red-shade: #c82728;
  --ion-color-red-tint: #e64142;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #282722;
  --ion-color-dark-rgb: 40,39,34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #23221e;
  --ion-color-dark-tint: #3e3d38;

  /**  grey **/
  --ion-color-grey: #5c5c5c;

  /** medium **/
  --ion-color-medium: #969692;
  --ion-color-medium-rgb: 150,150,146;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #848480;
  --ion-color-medium-tint: #a1a19d;

  /** light **/
  --ion-color-light: #f8f8f4;
  --ion-color-light-rgb: 248,248,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #dadad7;
  --ion-color-light-tint: #f9f9f5;

  /** white **/
  --ion-color-white: #FFFFFF;
  --ion-color-white-rgb: 255,255,255;
  --ion-color-white-contrast: #204B87;
  --ion-color-white-contrast-rgb: 32, 75, 135;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;

}

.ion-color-midnight {
  --ion-color-base: var(--ion-color-midnight);
  --ion-color-base-rgb: var(--ion-color-midnight-rgb);
  --ion-color-contrast: var(--ion-color-midnight-contrast);
  --ion-color-contrast-rgb: var(--ion-color-midnight-contrast-rgb);
  --ion-color-shade: var(--ion-color-midnight-shade);
  --ion-color-tint: var(--ion-color-midnight-tint);
}

.ion-color-gold {
  --ion-color-base: var(--ion-color-gold);
  --ion-color-base-rgb: var(--ion-color-gold-rgb);
  --ion-color-contrast: var(--ion-color-gold-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb);
  --ion-color-shade: var(--ion-color-gold-shade);
  --ion-color-tint: var(--ion-color-gold-tint);
}

.ion-color-red {
  --ion-color-base: var(--ion-color-red);
  --ion-color-base-rgb: var(--ion-color-red-rgb);
  --ion-color-contrast: var(--ion-color-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-red-shade);
  --ion-color-tint: var(--ion-color-red-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
}