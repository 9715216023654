// Colour scheme...

$blue-bright: #2376d8;
$blue-midnight: #1c3559;
$blue: #204b87;
$gold-dark: #745224;
$gold: #a9875a;
$grey-dark: #666666;
$grey-light: #b9b9b9;
$salmon: #ffa2a2;
$red: #e32c2d;

$box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);

// Breakpoints...
$mobile-sm-up: 375px;
$tablet-up: 768px;
$tablet-down: 767px;
$desktop-up: 992px;
$desktop-down: 991px;

// Basic html element styles...
h1,
h2,
h3,
h4 {
  color: var(--ion-color-secondary);
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
}

h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.2rem;
}
h3 {
  font-size: 1.1rem;
}
h4 {
  font-size: 1rem;
}

body {
  font-size: 16px;
  color: $grey-dark;
}
a {
  text-decoration: none;
  ion-icon {
    vertical-align: middle;
  }
}
p {
  margin: 0 0 15px 0;
  font-size: 15px;
}

// Helper classes...

.hidden {
  height: 0;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  width: 0;
}

@media (min-width: $tablet-up) {
  .mobile-only {
    display: none !important;
  }
}

@media (max-width: $tablet-down) {
  .tablet-only {
    display: none !important;
  }
}

.truncate {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}

.pointer-event-auto {
  pointer-events: auto;
}

// Class for safe padding top safe area (eg. accounts for notch on iPhones)
.padding-top-safe-area {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
