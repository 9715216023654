/*** Onboarding styling ***/

// Intro slides...
.swiper-slide {
  display: block;
}

swiper-container.intro-slides {
  background-image: url('../../assets/images/intro-bg-sm.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  // min-height: 540px;

  swiper-slide {
    background: rgba(255, 255, 255, 0.95);
    display: flex;
    height: 100%;
    padding: 20px;
    position: relative;

    .intro-slide-section {
      margin-top: -200px;
      max-width: 560px;

      img.intro-1-header {
        height: 80px;
        width: auto;
      }

      img.intro-2-header {
        height: 80px;
        width: auto;
      }

      .gas-pump-container {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 10px;
        width: calc(50vw + 50%);

        .intro-3-header {
          background: var(--ion-color-primary);
          border-radius: 40px 0 0 40px;
          height: 80px;
          padding: 17.5px 25px;
          text-align: left;
          width: calc(50vw + 50px);

          ion-icon {
            color: #ffffff;
            font-size: 45px;
          }
        }
      }
    }

    .intro-slide-controls {
      align-items: center;
      bottom: 50px;
      display: flex;
      flex-direction: column;
      left: 0;
      position: absolute;
      right: 0;
      width: auto;

      ion-button + ion-button {
        margin-top: 20px;
      }

      &.intro-3-slide-controls {
        margin-bottom: 72.59px;
      }
    }

    .intro-2-section {
      padding-top: 15px;
    }

    .intro-3-section {
      padding-top: 55px;
    }
  }
}

@media (min-width: $tablet-up) {
  swiper-container.intro-slides {
    background-image: url('../../assets/images/intro-bg-lg.jpg');
  }
}
