#map {
  height: 100%;
  width: 100%;
}

.overlay {
  content: "";
  filter: blur(5px);
}

.map-view {
  background: #e3e3e3;
  bottom: 0;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  top: 0;
  width: 100vw;

  .map-view-inner {
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    width: 100vw;
    z-index: 5;

    .map-floating-actions-top,
    .map-floating-actions {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      outline: none;
      padding-bottom: 30px;
      padding-left: 10px;
      padding-right: 10px;
      pointer-events: none;

      .feedback-button-wrapper {
        outline: none;
      }

      .btn-center {
        left: 22px;
        margin: 0 auto;
        position: relative;
        transition: opacity 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);

        &[disabled] {
          opacity: 0;
        }
      }

      ion-button {
        --box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
        outline: none;
        pointer-events: auto;

        &.scan-card-button {
          --background: rgb(27, 46, 77);
          --background-hover: rgb(49, 67, 94);
        }
      }
    }

    .map-floating-actions-top {
      padding-bottom: 0;
      justify-content: flex-end;

      .btn-icon {
        outline: none;

        svg {
          display: block;
        }

        span {
          margin-left: 10px;
        }

        &.btn-hidden {
          display: none;
        }

        &.btn-wide {
          min-width: 200px;
        }
      }
    }
  }
}

// Fixes scroll issues Shadow dom - Do not delete
.menu-content {
  height: 100vh;
  position: fixed;
}
