/*** List view cards ***/

.list-view-empty {
  --background: var(--ion-color-light);

  h3 {
    color: var(--ion-color-dark);
    margin: 60px 0 80px 0;
    text-align: center;
  }
}

ul.list-view-cards {
  background: var(--ion-color-white);
  display: block;
  list-style-type: none;
  margin: 0;
  min-height: 100%;
  padding: 0 0 10px 0;
  width: 100%;

  li.list-card {
    background: var(--ion-color-white);
    border: 1px solid #70707033;
    border-radius: 10px 0;
    margin: 20px 15px;
    
    .list-card-flags {
      height: 20px;
      color: var(--ion-color-light);

       .flag-partner .list-card-flag-partner {
        // Using inline svg below for better offline experience
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4000 63'%3E%3Cpolyline points='11.9 0 4000 0 4000 63 0 63' style='fill:%23a9875a'/%3E%3Cpolyline points='26.86 0 4000 0 4000 63 15 63' style='fill:%231c3559'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: cover;
        color: #ffffff;
        display: block;
        font-size: 12px;
        font-weight: 700;
        height: 30px;
        padding: 7px 5px 5px 20px;
        position: absolute;
        right: 0;
        text-transform: uppercase;
        top: 0;

        span {
          vertical-align: middle;
        }

        img {
          height: 30px;
          position: absolute;
          right: 15px;
          top: -6px;
          vertical-align: middle;
          width: 30px;
        }          
       }
    }

    .list-card-content {
      display: flex;
      flex-direction: row;
      min-height: 100px;

      .list-card-price-block {
        align-items: center;
        background-color: var(--ion-color-secondary);
        border-radius: 10px 0 0 0;
        color: var(--ion-color-light);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 0;
        position: relative;
        text-align: center;
        min-width: 93px;

        &.has-flag-best {
          padding-top: 7px;
        }

        &.has-discount-applied {
          background-color: var(--ion-color-midnight);
          border-bottom: 5px solid var(--ion-color-gold);
        }

        .list-card-flag-border {
          background-color: white;
          height: 21px;
          left: 0;
          position: absolute;
          top: 0;
          width: 105px;
          -webkit-clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
        }

        .list-card-flag-best {
          background-color: $red;
          border-radius: 10px 0 0 0;
          border-bottom: 1px solid white;
          border-right: 1px solid white;
          font-size: 11px;
          height: 21px;
          left: 0;
          padding: 3px 12px;
          position: absolute;
          text-align: left;
          top: 0;
          width: 104px;
          -webkit-clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
          clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);

          ion-icon {
            margin-right: 4px;
            margin-top: -2px;
            vertical-align: middle;
          }
        }

        .list-card-price-block-inner {
          text-align: center;
        }

        span.price {
          font-size: 22px;
          font-weight: 700;
        }

        span.updated {
          bottom: 0;
          color: rgba(255, 255, 255, 0.7);
          font-size: 11px;
          position: absolute;
          margin-bottom: 8px;
        }
      }
    }

    .list-card-content-block {
      width: 100%;
    }

    .list-card-centered-content {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: space-between;
      padding-bottom: 10px;
      padding-top: 10px;

      &.has-flag-partner {
        height: 80%;
      }
    }

    .list-card-flag-partner {
      background-color: var(--ion-color-midnight);
      color: var(--ion-color-light);
      font-size: 12px;
      height: 20px;
      text-align: right;

      &.has-discount-applied {
        border-left: 1px solid white;
      }

      img {
        margin: -7px 15px 0 10px;
        max-width: 21px;
      }
    }

    .list-card-info-container {
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    .list-card-info-right {
      flex-grow: 1;

      .list-card-info-content {
        margin-left: 15px;

        h3 {
          color: var(--ion-color-secondary);
          font-size: 13px;
          margin: 0 0 4px 0;        
        }

        > span {
          color: var(--ion-color-primary);
          display: block;
          font-size: 12px;
          line-height: 1.3;        
        }
      }
    }

    .list-card-actions{
      color: var(--ion-color-primary);
      display: flex;
      flex: 0 0 46px;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 12px;
      text-align: center;

      span.label-distance {
        @extend .truncate;

        display: block;
        font-size: 10px;
        font-weight: 700;
        max-width: 60px;
      }

      ion-button {
        --padding-bottom: 4px;
        --padding-end: 4px;
        --padding-start: 4px;
        --padding-top: 4px;
        height: 20px;

        ion-icon {
          font-size: 20px;
          margin-top: 0;
        }
      }    
    }
  }
}
