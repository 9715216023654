/*** Side menu styling ***/
.menu-panel-header {
  margin-top: 55px;
  padding: 36px 20px 26px 20px;
  text-align: center;

  ion-icon {
    font-size: 38px;
  }
}

.menu-panel-section {
  font-size: 16px;
  padding: 0 20px 40px 20px;
  text-align: center;

  p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  a {
    display: block;
    font-weight: 600;
    margin-bottom: 12px;
  }

  &.leftAlign {
    text-align: left;
    line-height: 22px;
    padding: 0 20px 30px 30px;

    p {
      margin-bottom: 0;
    }

    a {
      display: unset;
    }
  }
}

.menu-panel-section + .menu-panel-header {
  position: relative;

  &:before {
    content: "";
    background: $grey-light;
    height: 1px;
    left: 40px;
    position: absolute;
    right: 40px;
    top: 0;
    width: auto;
  }
}

.menu-panel-form-actions {
  margin-top: 40px;
  padding: 20px;
  text-align: center;
}

.center-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-button {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.icon-in-button {
  font-size: 1.5rem;
  margin-right: 8px;
}
